<template>
  <div class="billsdetial">
    <div class="table tableprint" ref="print">
      <el-table :data="list" stripe size="small" style="width: 100%">
        <el-table-column>
          <template #header>
            <el-row type="flex">
              <el-form-item label="所属公司">
                <el-col>
                  <el-cascader v-model="companyID" :options="companys" />
                </el-col>
              </el-form-item>

              <el-form-item label="转入库房" style="margin-left:20px;">
                <el-col>
                  <el-select v-model="form.storeCode" placeholder="库房" style="width: 110px">
                    <el-option v-for="item in dict.stores" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-form-item>

              <el-form-item label="转入渠道" style="margin-left:20px;">
                <el-col>
                  <el-select v-model="form.channel" placeholder="渠道" style="width: 110px">
                    <el-option v-for="item in dict.channels" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
              </el-form-item>
            </el-row>
            <el-row type="flex" align="right">
              <el-form-item label="调拨时间">
                <el-col>
                  <el-input v-model="form.billDate" disabled style="width: 160px" placeholder="自动生成"> </el-input>
                </el-col>
              </el-form-item>

              <el-form-item label="调拨单号" style="margin-left:20px;">
                <el-col>
                  <el-input v-model="form.billCode" autocomplete="off" style="width: 140px" placeholder="自动生成">
                  </el-input>
                </el-col>
              </el-form-item>
            </el-row>
          </template>
          <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>
          <el-table-column prop="detailCode" label="系统款号(SKU)" width="130"> </el-table-column>
          <el-table-column prop="detailName" label="名称"> </el-table-column>
          <el-table-column prop="storeName" label="原库房" width="170">
            <template #default="scope">
              <el-select v-model="scope.row.storeCode" @change="changeStore(scope.row)" placeholder="请选择原库房"
                style="width: 130px" size="small">
                <el-option v-for="item in dict.stores" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="channel" label="原渠道" width="170">
            <template #default="scope">
              <el-select v-model="scope.row.channel" @change="changeStore(scope.row)" placeholder="请选择原渠道"
                style="width: 130px" size="small">
                <el-option v-for="item in dict.channels" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="stockRemain" label="剩余数量" width="90"> </el-table-column>
          <el-table-column prop="stockNumber" label="本次转移数量" width="120">
            <template #default="scope">
              <el-input v-model="scope.row.stockNumber" autocomplete="off" type="number" style="width: 90px"
                size="small">
              </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="" width="80">
            <template #default="scope">
              <el-button @click="dele(scope.row)" type="primary" link size="small">[删除]</el-button>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-row type="flex" justify="center">
        <div style="margin: 15px 0">
          <el-button type="info" @click="openInput()" icon="plus">
            添加要转库转渠道的货品
          </el-button>
        </div>
      </el-row>
      <el-row type="flex" class="inputfeed" justify="center">
        <el-col :span="5">
          操作：
          <el-input size="small" style="width: 60%" v-model="form.handoverName"></el-input>
        </el-col>
        <el-col :span="5">
          交接：
          <el-input size="small" style="width: 60%" v-model="form.reviewerName"></el-input>
        </el-col>
        <el-col :span="5">
          复核：
          <el-input size="small" style="width: 60%" v-model="form.operatorName"></el-input>
        </el-col>
        <el-col :span="5">
          财务：
          <el-input size="small" style="width: 60%" v-model="form.financerName"></el-input>
        </el-col>
      </el-row>
    </div>
    <div class="billsbutton" style="margin:10px auto; text-align: center;">
      <el-button size="default" type="primary" @click="save()" icon="ArrowRight">
        调拨
      </el-button>
    </div>
  </div>
</template>
<script>
import { submitTransfer, fetchBillCode, fetchStoreDetail } from "@/api/stock";
export default {
  name: "Transfer",
  emits: ["dialogEvent"],
  data() {
    return {
      loading: false,
      list: [],
      details: [],

      dict: {
        stores: [],
        channels: [],
      },

      form: {
        billType: '库存调拨',
        storeCode: "", //转入库房
        channel: "", //转入渠道
        billDate: "", //时间
        billCode: "", //入库单据号,

        companyID: ''
      },

      companyID: [],
      // props: {
      //   checkStrictly: true,
      // },
      companys: []
    };
  },
  methods: {
    openInput() {
      let that = this;
      this.$prompt("请输入系统款式编号(SKU)", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "promptDialog",
      }).then(({ value }) => {
        if (value) {
          fetchStoreDetail(value).then((res) => {
            if (res.data.length == 0) {
              that.$message({
                type: "warning",
                message: "未找到该款号信息: " + value,
              });
              return;
            }

            let defaultItem = res.data[0];
            if (
              that.details.filter(function (item) {
                return item.detailCode == defaultItem.detailCode;
              }).length == 0
            ) {
              that.details.push({
                detailCode: defaultItem.detailCode,
                list: res.data,
              });
            }
            that.list.push({
              detailCode: defaultItem.detailCode,
              detailName: defaultItem.detailName,
              storeCode: defaultItem.storeCode,
              channel: defaultItem.channel,
              stockRemain: defaultItem.stockRemain,
              stockNumber: 0,
            });
          });
        }
      });
    },
    changeStore(row) {
      let that = this;
      //款的所有渠道明细
      let detailDatas = that.details.filter(function (item) {
        return item.detailCode == row.detailCode;
      });

      let channelDatas = [];
      //款对应的渠道明细
      if (detailDatas.length > 0) {
        channelDatas = detailDatas[0].list.filter(function (item) {
          return item.storeCode == row.storeCode && item.channel == row.channel;
        });
      }

      row.stockRemain = 0;
      if (channelDatas.length > 0) {
        row.stockRemain = channelDatas[0].stockRemain;
      }
    },
    save() {
      let that = this;
      if (this.list.length <= 0) {
        this.$message({
          message: "请输入至少一条数据",
          type: "warning",
        });
        return;
      }
      if (
        this.form.storeCode == "" ||
        this.form.channel == "" ||
        this.form.billDate == "" ||
        this.form.billCode == ""
      ) {
        this.$message({
          message: "必填信息不可为空",
          type: "warning",
        });
        return;
      }

      let validMsg = "";
      for (var i = 1; i <= that.list.length; i++) {
        let item = that.list[i - 1];
        if (
          item.storeCode === that.form.storeCode &&
          item.channel === that.form.channel
        ) {
          validMsg += i + "行 库房及渠道不能与转出的库房及渠道与相同 <br>";
        }

        if (item.stockNumber <= 0) validMsg += i + "行 转移数量错误 <br>";
        if (item.stockNumber > item.stockRemain)
          validMsg += i + "行 转移数量不能大于总数量 <br>";

        let tempDetails = that.list.filter(function (tempItem) {
          return (
            item.storeCode === tempItem.storeCode &&
            item.channel === tempItem.channel &&
            item.detailCode === tempItem.detailCode
          );
        });
        if (tempDetails.length > 1) {
          validMsg += i + "行 同一款在同一渠道不能转出多次 <br>";
        }
      }

      if (validMsg.length > 0) {
        this.$message({
          message: validMsg,
          type: "warning",
          dangerouslyUseHTMLString: true,
        });
        return;
      }

      console.log(this.list);
      this.form.channelCode = this.form.channel;
      this.form.companyID = this.companyID[this.companyID.length - 1];
      submitTransfer({ bill: this.form, details: this.list }).then((res) => {
        that.$message({
          type: res.type,
          message: res.content,
        });
        this.$emit("dialogEvent", "transferDialogVisible");
      });
    },
    dele(row) {
      console.log(this.list);
      this.list = this.list.filter(function (item) {
        return item.detailCode != row.detailCode;
      })
    }
  },
  mounted() {
    this.$kaung.dictionary("stock", "store").then(res =>  this.dict.stores = res);
    this.$kaung.dictionary("stock", "channel").then((res) => { this.dict.channels = res; });

    this.$kaung.companys().then((res) => { this.companys = res; });

    let that = this;
    fetchBillCode({
      billType: "库存调拨",
      prev: "DB",
    }).then((res) => {
      that.form.billCode = res.data;
      that.form.billDate = that.$util.fmtDate(new Date());
    });
  },
};
</script>
<style lang="less">
.el-message-box__wrapper {
  z-index: 3001 !important;
}

.v-modal {
  z-index: 3000 !important;
}
</style>