<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">进销存</el-breadcrumb-item>
        <el-breadcrumb-item>渠道库存</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="querys" ref="querys" label-width="90px" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" prefix-icon="search" @keyup.enter="fetchs"> </el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="SKU编号" prop="detailCode">
          <el-col style="width: 160px">
            <el-input v-model="detailCode" @keyup.enter="fetchs"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-col style="width: 120px">
            <el-select v-model="enabled" clearable placeholder="请选择" @change="fetchs()">
              <el-option key="1" label="当前状态" value="1"></el-option>
              <el-option key="0" label="历史记录" value="0"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">搜索</el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button type="primary" icon="Sort" @click="transferDialogVisible = true">渠道货品调拨</el-button>
      <el-button type="primary" icon="refresh" @click="resetSkuStore()" style="display: none;">重置款式库存</el-button>
      <!-- <el-button type="primary" icon="el-icon-connection" size="small" @click="transferSkuStore()" > 库存调拨 </el-button> -->

      <el-button type="primary" icon="download" @click="exportEvent()">导出</el-button>
    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
      <template v-slot:productImg="scope">
        <el-image style="height: 32px; width: 32px" lazy :src="apiUrl + 'static/code/' + scope.row.detailCode + '?t=0'"
          :preview-src-list="[apiUrl + 'static/code/' + scope.row.detailCode + '?t=1']">
          <template #error>
            <img style="width: 100%" src="~@/assets/imgunfine.jpg" alt="" />
          </template>
        </el-image>
      </template>
      <template v-slot:enabled="scope">
        <el-tag size="small" v-if="scope.row.enabled">
          {{ scope.row.enabled ? "当前" : "历史" }}
        </el-tag>
      </template>
      <!-- <template v-slot:toolbar="scope">
        <el-button
          @click="freshenChannel(scope.row)"
          link
          size="small"
          class="hide"
        >
          [重置渠道库存]
        </el-button>
      </template> -->
    </static-table>
  </el-card>

  <!-- 渠道调拨弹窗组件 -->
  <div class="xlsdialog">
    <el-dialog title="渠道库存调拨" v-model="transferDialogVisible" :show-close="true" :close-on-click-modal="false"
      destroy-on-close top="5vh" @closed="transferDialogVisible = false" width="80%" center>
      <transfer @dialog-event="dialogEvent"></transfer>
    </el-dialog>
  </div>
</template>
<script>
import transfer from "@/views/stock/bills/transfer.vue";
import { fetchStockStoreList, resetSkuStore } from "@/api/stock";
import StaticTable from "@/components/static-table.vue";
export default {
  name: "StoreIndex",
  components: { StaticTable, transfer },
  data() {
    return {
      apiUrl: '',
      detailCode: "",
      enabled: "1",
      transferDialogVisible: false,

      querys: {
        keyword: "",
        filters: [],
        columns: [
          { type: "index", label: "序号" },
          { prop: "storeName", label: "库房", sortable: true, searchable: true, width: "70", showOverflowTooltip: true, },
          { prop: "channel", label: "渠道", sortable: true, searchable: true, width: "80", showOverflowTooltip: true, },
          { prop: "detailCode", label: "款号(SKU)", sortable: true, searchable: true, width: "130", showOverflowTooltip: true, },
          { type: "template", prop: "productImg", label: "图片", width: "80", },
          { prop: "detailName", label: "款式名称", sortable: true, searchable: true, width: "180", showOverflowTooltip: true, },
          { prop: "stockRemain", label: "数量", sortable: true, searchable: true, width: "80", align: "right", },
          { prop: "avgPurchasePrice", label: "平均采购价", sortable: true, width: "130", align: "right", },
          { prop: "createDate", label: "更新时间", sortable: true, width: "150", },
          { type: "template", prop: "enabled", label: "状态", sortable: true, width: "70", },
          { prop: "billCode", label: "来源单据", sortable: true, width: "120", showOverflowTooltip: true,  },
          // { type: "toolbar", label: "操作", width: "120" },
        ],
      },
      pager: {
        sidx: "createDate", //默认的排序字段
        sord: "descending",
      },
    };
  },
  mounted() {
    this.fetchs();

    this.apiUrl = process.env.VUE_APP_BASEURL;
  },
  methods: {
    fetchs(methodName) {
      if ((typeof methodName) == 'object') methodName = null;
      this.querys.filters = [];
      if (this.detailCode)
        this.querys.filters.push({
          name: "detailCode",
          value: "%" + this.detailCode + "%",
          operate: 5,
        });
      //当前/历史
      if (this.enabled) {
        this.querys.filters.push({
          name: "enabled",
          value: this.enabled,
          operate: 0,
        });
      }

      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };

        search.method = methodName || '';
      fetchStockStoreList(search).then((res) => {
        if (search.method == 'Export') {
          this.$notify({
            title: "导出成功",
            message: res.content,
            type: "success",
            duration: 10000
          });
          window.location.href=process.env.VUE_APP_BASEURL + res.data;
        }
        else {
          this.pager = { ...res.data };
        }
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
    resetSkuStore() {
      let that = this;
      let detailCode = (this.currRow || {}).detailCode || this.querys.keyword || "";
      resetSkuStore(detailCode).then((res) => {
        if (res.code == 200) {
          that.$notify({
            title: "刷新成功",
          });
          that.fetchs();
        }
      });
    },

    exportEvent() {
      this.fetchs('Export');
    },
    
    dialogEvent(data) {
      this.transferDialogVisible = false;
      this.fetchs();
    },
  },
};
</script>
<style lang="less">
.xlsdialog {
  .el-dialog__body {
    max-height: 80vh;
    overflow-y: auto;
  }
}

.el-form .el-date-editor {
  width: 220px;
}
</style>>

